// 双向转诊模块接口
import request from '@/router/axios';
// 转出列表接口
export const getListOutAPI = () => request({
  url: '/api/blade-remote/mutualreferralhospital/pageOutHospitalizationReferral',
  method: 'get'
})
// 转入列表接口
export const getListInAPI = () => request({
  url: '/api/blade-remote/mutualreferralhospital/pageInHospitalizationReferral',
  method: 'get'
})
