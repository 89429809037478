<template>
  <div>
    <van-nav-bar
      title="本院转入"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        :offset="20"
        @load="onLoad"
      >
        <van-card
          desc=""
          class="card"
          v-for="(item,index) in info"
          :key="index"
        >
<template #title>
  <div class="title">
              <span>{{ item.patientName }}</span>
              <span style="padding-left: 20px">{{
                item.patientSex == 1 ? "男" : "女"
              }}</span>
              <span style="padding-left: 20px">{{
                item.patientAge == "" ? item.patientAge : item.patientAge + "岁"
              }}</span>
  </div>
</template>

          <template #desc>
          <div class="text">
                        <p>
                          <i class=" iconfont icon-shijian"></i>
         提交时间： <span >{{ item.submitTime }}</span>
            </p>
            <p>
               <i class=" iconfont  icon-Icon-yuyuemenzhen"></i>
              门诊号：<span>{{ item.medicalRecordNo }}</span>
            </p>
            <p>
              <i class=" iconfont  icon-bianhaodanhao"></i>
              申请单号：<span>{{ item.applyOrderId }}</span>
            </p>
            <p>
               <i class=" iconfont  icon-zhuanjia"></i>
              转诊专家：<span>{{ item.targetUserName }}</span>
            </p>
            <p>
               <i class=" iconfont  icon-guanlikeshi"></i>
              转诊科室:<span>{{ item.targetDeptName }}</span>
            </p>
            <p>
               <i class=" iconfont  icon-zhuanyuan-copy"></i>
              转诊医院:<span>{{ item.targetTenantName }}</span>
            </p>
          </div>

          </template>
          <template #tags>
            <van-tag type="primary" class="tag1">{{
              item.applyOrderStatus
            }}</van-tag>
          </template>
        </van-card>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getListInAPI } from "@/api/reportDualReferral";
export default {
  data() {
    return {
      isLoading: false, //下拉刷新
      loading: false, //上拉加载
      finished: false, //加载与不加载状态
      incomePayDate: {
        pageNumber: 1, //请求第几页数
        pageSize: 10, //请求每页条数
      },
      info: [], //列表
      list: [], //用于上拉将数据合并
      total: 0, //总页数
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
       this.loading=true
      getListInAPI(
        this.incomePayDate.pageNumber,
        this.incomePayDate.pageSize
      ).then((res) => {
        if (res.data.code == "200") {
           this.loading=false
          console.log(res.data.data, "双向转诊");
          this.info = res.data.data.records;
          this.total = res.data.data.total; // 总条数
          if (this.info.length >= this.total) {
            this.finished = true;
          }
        }
        this.info.forEach((item) => {
          switch (item.applyOrderStatus) {
            case "wait_submit":
              item.applyOrderStatus = "待提交";
              break;
            case "wait_primaryaudit":
              item.applyOrderStatus = "待审核";
              break;

            case "wait_scheduling":
              item.applyOrderStatus = "待安排";
              break;

            case "wait_diagnosis":
              item.applyOrderStatus = "待入院";
              break;
            case "diagnosis_ing":
              item.applyOrderStatus = "已入院";
              break;
            case "diagnosis":
              item.applyOrderStatus = "已出院";
              break;
            case "cancel":
              item.applyOrderStatus = "已取消";
              break;
            case "overdue":
              item.applyOrderStatus = "已过期";
              break;
            case "nullify":
              item.applyOrderStatus = "已作废";
              break;
            case "order_return":
              item.applyOrderStatus = "已退回";
              break;
            default:
              break;
          }
        });
        console.log(res.data.data.records, "双向");
      });
    },
    //下拉刷新
    onRefresh() {
      this.incomePayDate.pageNumber = 1; //起始页为第一页
      this.finished = false;
      setTimeout(() => {
        this.isLoading = false;
        this.getList();
        this.$toast("刷新成功");
      }, 700);
    },
    //上拉加载
    onLoad() {
      this.incomePayDate.pageNumber++;
      getListInAPI(
        this.incomePayDate.pageNumber,
        this.incomePayDate.pageSize
      ).then((res) => {
        if (res.data.code == "200") {
          console.log(res.data.data, "双向转诊上拉");
          this.list = res.data.data.records;
          this.total = res.data.data.total; // 总条数
          this.list.forEach((item) => {
            this.info.push(item);
            switch (item.applyOrderStatus) {
              case "wait_submit":
                item.applyOrderStatus = "待提交";
                break;
              case "wait_primaryaudit":
                item.applyOrderStatus = "待审核";
                break;

              case "wait_scheduling":
                item.applyOrderStatus = "待安排";
                break;

              case "wait_diagnosis":
                item.applyOrderStatus = "待入院";
                break;
              case "diagnosis_ing":
                item.applyOrderStatus = "已入院";
                break;
              case "diagnosis":
                item.applyOrderStatus = "已出院";
                break;
              case "cancel":
                item.applyOrderStatus = "已取消";
                break;
              case "overdue":
                item.applyOrderStatus = "已过期";
                break;
              case "nullify":
                item.applyOrderStatus = "已作废";
                break;
              case "order_return":
                item.applyOrderStatus = "已退回";
                break;
              default:
                break;
            }
          });
          this.loading = false;
          if (this.info.length >= this.total) {
            this.finished = true;
          }
        }
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  position: relative;
}

.tag1 {
  position: absolute;
  right: 2px;
  top: 5px;
}
.text{
color: #999999;
font-size: 14px;
}
.title{
  font-size: 16px;
  font-weight: bolder;
}
.iconfont{
font-size: 18px;
padding-top: 12px;
vertical-align: middle;
}
</style>>
